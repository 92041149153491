<template>
  <div id="app"
       class="topframe">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted () {
    if (this.isMobile()) {
      window.location.href = 'http://m.daline.com.cn/#/Index'
    }
  },
  methods: {
    isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style lang="less" >
#app {
  min-width: 1500px;
}
@import "./style/common";
@import './style/font';

</style>
