import { restful } from './restful.js'


async function login (info) {
  info.grant_type = 'password'
  info.scope = ''
  var clientBase = window.btoa('daline' + ':' + 'daline')
//   http://api-dev.daline.com.cn/dalineweb/pu-sys-usercenter-v1x0x1/oauth/token
  var token = await restful.post('/pu-sys-usercenter-v1x0x1/oauth/token', info, {
    headers: {
      Authorization: 'Basic ' + clientBase,
      response_type: 'token'
    },
    baseURL: "/dalgw",
  })
  return token
}
export async function userLogin (info) {
  var ret = await login(info)
  if (ret.access_token) {
    localStorage.setItem('userToken', JSON.stringify(ret))
    localStorage.setItem('defaultToken', JSON.stringify(ret))
  }

  return ret
}
export async function guestLogin () {
  var data = {username: 'kun.z', password: 'Hell0DAL'}
  var ret = await login(data)

  if (ret.access_token) {
    localStorage.setItem('guestToken', JSON.stringify(ret))
    localStorage.setItem('defaultToken', JSON.stringify(ret))
  }

  return ret
}
export async function logout () {
  localStorage.removeItem('userToken')
  var ret = {code: 0, info: 'success', content: ''}
  return ret
}
export function isGuestLoggedIn () {
  var token = localStorage.getItem('guestToken')
  token = JSON.parse(token)
  if (token) {
    return true
  } else {
    return false
  }
}
export function isUserLoggedIn () {
  var token = localStorage.getItem('userToken')
  token = JSON.parse(token)
  if (token) {
    return true
  } else {
    return false
  }
}
