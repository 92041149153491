export default {
    bind(el, {
        modifiers,
        value
    }) {
        el.style.position = "fixed";
        el.style.zIndex = 99999;
        let {
            x,
            y
        } = modifiers;


        el.addEventListener("mousedown", donw);

        var disX, disY;

        function donw(e) {
            disX = e.offsetX;
            disY = e.offsetY;
            document.addEventListener("mousemove", move);
            document.addEventListener("mouseup", up)


        }

        function move(e) {
            var l = e.clientX - disX;
            var t = e.clientY - disY;


            if (value) {

                if (x) {
                    el.style.left = l + 'px';
                }
                if (y) {
                    el.style.top = t + 'px';
                }

                if (x && y || (!x && !y)) {
                    el.style.left = l + 'px';
                    el.style.top = t + 'px';
                }

            }

        }

        function up() {
            document.removeEventListener("mousemove", move);
            document.removeEventListener("mouseup", up);
        }
    }
}