import config from '@/config/env.js'

// websocket 实时数据
export const websocketUrl = config.WEBSOCKET
// graphql接口
export const graphQLUrl = config.GRAPHQL
// 后台网站接口
export const restfulUrl = config.RESTFUL
// 本地接口
export const localUrl = config.LOCAL
// 图片路径
export const baseImgPath = config.BASEIMGPATH
