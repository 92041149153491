// 不管是 graphql 还是 resetful，只要是 http 请求，都复用此实例
export const commonAxiosConf = {
  // 统一的超时时间
  timeout: 30 * 1000,
  withCredentials: true
}

// 统一的请求拦截，此处可以统一对请求做前后置处理，错误处理，日志记录等
export const commonInterceptors = {
  // 请求前拦截
  beforeRequest (config) {
    // Do something before request is sent
    return config
  },
  // 请求出错拦截
  onRequestError (error) {
    // Do something with request error
    return Promise.reject(error)
  },
  // 响应拦截
  afterResponse (response) {
    // Do something before response
    return response
  },
  // 响应出错拦截
  onResponseError (error) {
    // Do something with response error
    return Promise.reject(error)
  }
}
