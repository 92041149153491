import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { VueShowdown } from 'vue-showdown';
import './theme/element-variables.scss';
import { guestLogin } from "./api/login.js";


//引入全局指令
import directive from '@/directive/index'
Vue.use(directive)

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component('vueshowdown', VueShowdown);
guestLogin()
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//   /* 需要注册百度地图开发者来获取你的ak */
//   ak: 'BO5EzBQehA6qp5vhF3GDiaGWXR4XRdQA'
// })

new Vue({
	render:h => h(App),
	el: '#app',
	router,
	store,
	template: '<App/>'
})
