const modeUrlObj = {
  production: {
    GRAPHQL: '/graph',
    RESTFUL: '/dalgw',
    WEBSOCKET: 'http://prod.daline.com.cn:8061/',
    LOCAL: '/local',
    BASEIMGPATH: '/img/'
  },
  development: {
    GRAPHQL: '/graph',
    RESTFUL: '/dalgw',
    WEBSOCKET: 'http://dev.daline.com.cn:8061/',
    LOCAL: '/local',
    BASEIMGPATH: '/img/'
    // LOCAL: 'http://localhost:8083'
  },    
  test: {
    GRAPHQL: '/graph',
    RESTFUL: '/dalgw',
    WEBSOCKET: 'http://test.daline.com.cn:8061/',
    LOCAL: '/local',
    BASEIMGPATH: '/img/'
    // LOCAL: 'http://localhost:8083'
  }
}

export default modeUrlObj[process.env.NODE_ENV]   
