import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
	dal_token: undefined
}

const mutations = {
	saveToken(state, token){
		state.dal_token = token;
	}
}

const actions = {
	async saveToken(context,token){
        context.commit('saveToken',token);
	}
}

export default new Vuex.Store({
	state,
	actions,
	mutations,
})
