import axios from "axios";
import _merge from "lodash/merge";
import { commonAxiosConf, commonInterceptors } from "./config.js";
import { restfulUrl } from "./global";

axios.defaults.headers.get["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 创建请求实例
export const restful = axios.create(
  _merge({}, commonAxiosConf, {
    baseURL: restfulUrl, // 接口地址
  })
);



// 统一的接口拦截
restful.interceptors.request.use(
  (config) => {
    let fdata = new FormData();
    for (var key in config.data) {
      fdata.append(key, config.data[key]);
    }
    config.data = fdata;
    if (!("Authorization" in config.headers)) {
        // console.log('=============',localStorage);
      if (localStorage.getItem("userToken")) {

        let lt = localStorage.getItem("userToken");
        lt = JSON.parse(lt);
        config.headers.Authorization = lt.token_type + " " + lt.access_token;
      } else if (localStorage.getItem("guestToken")) {
        let lt = localStorage.getItem("guestToken");
        lt = JSON.parse(lt);
        config.headers.Authorization = lt.token_type + " " + lt.access_token;
      }
    }
    return commonInterceptors.beforeRequest(config);
  },
  (error) => {

    return commonInterceptors.onRequestError(error.response.data);
  }
);

restful.interceptors.response.use(
  (response) => {
    //   var ret = {code: 0, info: 'success', content: response.data}
    return response.data;
  },
  (error) => {

    commonInterceptors.onResponseError(error);
    var ret;
    if (error.response.status == 500) {
      localStorage.clear("userToken")
      ret = { code: 1, info: "failed", content: error.response.data };
    } else {
      ret = { code: 1, info: "failed", content: error.message };
    }
    return ret;
  }
);

export default restful;
