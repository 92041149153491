import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const dal_headfloat_on_menu = r => require.ensure([], () => r(require('@/components/dal-headfloat-on-menu')), 'dal-headfloat-on-menu');
const dal_menu_on_main = r => require.ensure([], () => r(require('@/components/dal-menu-on-main')), 'dal-menu-on-main');
const dal_header = r => require.ensure([], () => r(require('@/components/dal-header')), 'dal-header');
const dal_footer = r => require.ensure([], () => r(require('@/components/dal-footer')), 'dal-footer');
const Index = r => require.ensure([], () => r(require('@/pages/Index')), 'Index');
const Afterlanding = r => require.ensure([], () => r(require('@/pages/Afterlanding')), 'Afterlanding');
const Productsunderdevelopment = r => require.ensure([], () => r(require('@/pages/Productsunderdevelopment')), 'Productsunderdevelopment');
const Productsinoperation = r => require.ensure([], () => r(require('@/pages/Productsinoperation')), 'Productsinoperation');
const Complaintsandsuggestions = r => require.ensure([], () => r(require('@/pages/Complaintsandsuggestions')), 'Complaintsandsuggestions');
const Resourcedetails = r => require.ensure([], () => r(require('@/pages/Resourcedetails')), 'Resourcedetails');
const BestAfterlanding = r => require.ensure([], () => r(require('@/pages/BestAfterlanding')), 'BestAfterlanding');
const Integratedbusinessdevelopmentplatform = r => require.ensure([], () => r(require('@/pages/Integratedbusinessdevelopmentplatform')), 'Integratedbusinessdevelopmentplatform');
const Privatetrafficconstruction = r => require.ensure([], () => r(require('@/pages/Privatetrafficconstruction')), 'Privatetrafficconstruction');
const Digitalcapitalization = r => require.ensure([], () => r(require('@/pages/Digitalcapitalization')), 'Digitalcapitalization');
const Newscenter = r => require.ensure([], () => r(require('@/pages/Newscenter')), 'Newscenter');
const sale = r => require.ensure([], () => r(require('@/pages/sale')), 'sale');
const Customerlist = r => require.ensure([], () => r(require('@/pages/Customerlist')), 'Customerlist');
const productmanager = r => require.ensure([], () => r(require('@/pages/productmanager')), 'productmanager');
const Projectlist = r => require.ensure([], () => r(require('@/pages/Projectlist')), 'Projectlist');
const Globalresources = r => require.ensure([], () => r(require('@/pages/Globalresources')), 'Globalresources');
const Physicalresourcemonitoring = r => require.ensure([], () => r(require('@/pages/Physicalresourcemonitoring')), 'Physicalresourcemonitoring');
const Backupmonitoring = r => require.ensure([], () => r(require('@/pages/Backupmonitoring')), 'Backupmonitoring');
const Unitsubmissionmonitoring = r => require.ensure([], () => r(require('@/pages/Unitsubmissionmonitoring')), 'Unitsubmissionmonitoring');
const Logmonitoring = r => require.ensure([], () => r(require('@/pages/Logmonitoring')), 'Logmonitoring');
const Businessunit = r => require.ensure([], () => r(require('@/pages/Businessunit')), 'Businessunit');
const Productmanagercommunicationproject = r => require.ensure([], () => r(require('@/pages/Productmanagercommunicationproject')), 'Productmanagercommunicationproject');
const UIdesignproject = r => require.ensure([], () => r(require('@/pages/UIdesignproject')), 'UIdesignproject');
const Contentmanagement = r => require.ensure([], () => r(require('@/pages/Contentmanagement')), 'Contentmanagement');
const UIdevelopmentproject = r => require.ensure([], () => r(require('@/pages/UIdevelopmentproject')), 'UIdevelopmentproject');
const channelmanagement = r => require.ensure([], () => r(require('@/pages/channelmanagement')), 'channelmanagement');
const Productmanageroperationproject = r => require.ensure([], () => r(require('@/pages/Productmanageroperationproject')), 'Productmanageroperationproject');
const article = r => require.ensure([], () => r(require('@/pages/article')), 'article');
const Aboutus = r => require.ensure([], () => r(require('@/pages/Aboutus')), 'Aboutus');
const edgepower = r => require.ensure([], () => r(require('@/pages/edgepower')), 'edgepower');
const question = r => require.ensure([], () => r(require('@/pages/question')), 'question');
const Information = r => require.ensure([], () => r(require('@/pages/Information')), 'Information');
const recruitment = r => require.ensure([], () => r(require('@/pages/recruitment')), 'recruitment');
const NewInformation = r => require.ensure([], () => r(require('@/pages/NewInformation')), 'NewInformation');
const Newrecruitment = r => require.ensure([], () => r(require('@/pages/Newrecruitment')), 'Newrecruitment');
const makerecruitment = r => require.ensure([], () => r(require('@/pages/makerecruitment')), 'makerecruitment');
const Enterprise = r => require.ensure([], () => r(require('@/pages/Enterprise')), 'Enterprise');
const EidtInformation = r => require.ensure([], () => r(require('@/pages/EidtInformation')), 'EidtInformation');
const float = r => require.ensure([], () => r(require('@/pages/float')), 'float');
const comment = r => require.ensure([], () => r(require('@/pages/comment')), 'comment');
const DalCompBanner = r => require.ensure([], () => r(require('@/components/dal-comp-banner')), 'DalCompBanner');
const routes =
  [
    {
      path: "/dal-menu-on-main",
      component: dal_menu_on_main,
      name: "",
      meta:
        [
        ],
      children:
        [
          {
            path: "/dal-header",
            component: dal_header,
            name: "",
            meta:
              [
              ],
            children:
              [
                {
                  path: "/dal-footer",
                  component: dal_footer,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                      {
                        path: "/Afterlanding",
                        component: Afterlanding,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                      {
                        path: "/Productsunderdevelopment",
                        component: Productsunderdevelopment,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                      {
                        path: "/Productsinoperation",
                        component: Productsinoperation,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                      {
                        path: "/Complaintsandsuggestions",
                        component: Complaintsandsuggestions,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                      {
                        path: "/Resourcedetails",
                        component: Resourcedetails,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                      {
                        path: "/BestAfterlanding",
                        component: BestAfterlanding,
                        name: "",
                        meta:
                          [
                          ],
                        children:
                          [
                          ],
                      },
                    ],
                },
                {
                  path: "/sale",
                  component: sale,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Customerlist",
                  component: Customerlist,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/productmanager",
                  component: productmanager,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Projectlist",
                  component: Projectlist,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Globalresources",
                  component: Globalresources,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Physicalresourcemonitoring",
                  component: Physicalresourcemonitoring,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Backupmonitoring",
                  component: Backupmonitoring,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Unitsubmissionmonitoring",
                  component: Unitsubmissionmonitoring,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Logmonitoring",
                  component: Logmonitoring,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Businessunit",
                  component: Businessunit,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Productmanagercommunicationproject",
                  component: Productmanagercommunicationproject,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/UIdesignproject",
                  component: UIdesignproject,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Contentmanagement",
                  component: Contentmanagement,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/UIdevelopmentproject",
                  component: UIdevelopmentproject,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/channelmanagement",
                  component: channelmanagement,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Productmanageroperationproject",
                  component: Productmanageroperationproject,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Information",
                  component: Information,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/recruitment",
                  component: recruitment,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/NewInformation",
                  component: NewInformation,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Newrecruitment",
                  component: Newrecruitment,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/makerecruitment",
                  component: makerecruitment,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Enterprise",
                  component: Enterprise,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/EidtInformation",
                  component: EidtInformation,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/float",
                  component: float,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
              ],
          },

        ],
    },
    {
      path: "/question",
      component: question,
      name: "",
      meta:
        [
        ],
      children:
        [
        ],
    },
    {
      path: "/comment",
      component: comment,
      name: "",
      meta:
        [
        ],
      children:
        [
        ],
    },
    {
      path: "/dal-headfloat-on-menu",
      component: dal_headfloat_on_menu,
      name: "",
      meta:
        [
        ],
      children:
        [
          {
            path: "/dal-footer",
            component: dal_footer,
            name: "",
            meta:
              [
              ],
            children:
              [
                {
                  path: "/Index",
                  component: Index,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/banner",
                  component: DalCompBanner,
                  name: '',
                  children: [
                    {
                      path: "/Integratedbusinessdevelopmentplatform",
                      component: Integratedbusinessdevelopmentplatform,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/article",
                      component: article,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/Privatetrafficconstruction",
                      component: Privatetrafficconstruction,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/Digitalcapitalization",
                      component: Digitalcapitalization,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/edgepower",
                      component: edgepower,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/Aboutus",
                      component: Aboutus,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                    {
                      path: "/Newscenter",
                      component: Newscenter,
                      name: "",
                      meta:
                        [
                        ],
                      children:
                        [
                        ],
                    },
                  ]
                },
                /* {
                  path:"/Integratedbusinessdevelopmentplatform",
                  component:Integratedbusinessdevelopmentplatform,
                  name:"",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path:"/Privatetrafficconstruction",
                  component:Privatetrafficconstruction,
                  name:"",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path:"/Digitalcapitalization",
                  component:Digitalcapitalization,
                  name:"",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                }, */
                /* {
                  path:"/Newscenter",
                  component:Newscenter,
                  name:"",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                }, */
                {
                  path: "/article",
                  component: article,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
                {
                  path: "/Aboutus",
                  component: Aboutus,
                  name: "",
                  meta:
                    [
                    ],
                  children:
                    [
                    ],
                },
              ],
          },
        ],
    },
    {
      path: "/",
      redirect: "/Index",
      component: Index,
    },
    {
      path: "/test",
      component: () => import('@/pages/test.vue'),
    },
  ]

const router = new Router({
  routes,
  strict: process.env.NODE_ENV !== 'production',
})
// 路由切换后，页面回到顶部
router.afterEach(() => document.documentElement.scrollTop = 0)
export default router
